// sass --watch index.scss index.css
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
=============== 
Properties
===============
*/
@font-face {
  font-family: "Caveat";
  font-style: normal;
  font-weight: normal;
  src: url("../asset/font/Caveat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  src: url("../asset/font/Raleway-Light.ttf") format("truetype");
}

body {
  font-family: raleway;
  color: #888784;
  margin: 0;
  padding: 0;
  font-weight: 100;
  overflow-x:hidden;
}

h2 {
  color: #000;
  font-size: 24px;
  font-weight: 300;
}

h4 {
  color: #000;
  font-weight: normal;
}

.section-title {
  font-size: 40px;
  font-family: 'raleway';
  color: #888784;
  margin-bottom: 0;
  font-weight: 300;
  margin-top: 2em;
}

/*
=============== 
Header and Navigation
===============
*/
.header {
  position: sticky;
  z-index: 2;
  top: 0;
  background: linear-gradient(rgba(229, 239, 254, 0.26), rgba(229, 252, 254, 0)) 50% 50%/cover;
  border-bottom: 1px solid #888784;
  background: white;

  .nav-container {
    display: grid;
    grid-template-columns: 1fr 5fr;

    h1 {
      font-size: 20px;
      font-weight: 400;
      line-height: 1;
      margin: 10px 0;
      color: #898885;
      font-family: "Raleway";
      text-align: left;
      width: max-content;

      span {
        font-weight: 300;
        display: block;
        font-size: 16px;
      }
    }

    .site-logo-s {
      height: 34px;
      margin: 10px;
    }

    .nav-btn {
      display: none;
    }

    .nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .active {
        text-decoration: none;
        color: #888784;
        font-weight: 400;
      }
    }
   

    .nav-page-ttl-active {
      transition: all .1s linear;
      background: linear-gradient(to right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: nav-animation .5s linear;

      @keyframes nav-animation {
        to {
          background: linear-gradient(right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-position: 50px;
        }
      }

      &:active {
        text-decoration: none;
      }
    }

  }
}

.sp-nav-container {
  top: 15%;
  position: fixed;

  .nav-item {
    text-align: center;
    display: block;
    text-decoration: none;
    color: #888784;
    margin: 2em auto;
    cursor: default;
    font-weight: 400;
    font-family: raleway;
    font-size: 18px;

    &:hover {
      color: #888784;
      transition: all 0.1s linear;
      transform: scale(1.1);
    }

    &:active {
      text-decoration: none;
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  opacity: 0;
  transition: all 0.3s linear;
  ;
  transform: translateX(-100%);
}

.show-sidebar {
  opacity: 1;
  transform: translateX(0);
  background: #f1f5f8;
}

.close-btn {
  position: absolute;
  right: 4.75%;
  top: 2.75%;
  font-size: 2.5rem;
  background: none;
  border: none;
  color: #888784;
  cursor: pointer;
  font-size: 24px;
  font-weight: lighter;
  font-family: inherit;
}

@keyframes spNabMenuvAnimation {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/*
=============== 
Section1. Hero
===============
*/
.first-section {
  margin-top: 4em;
}

.top-banner-container {
  .top-banner {
    width: 100%;
  }

  .top-banner-blur {
    z-index: 3;
    position: absolute;
    top: 150px;
    left: 25%;
    background: #ffffffc4;
    text-align: center;
    height: 50%;
  }

  .banner-title {
    z-index: 4;
    top: 10%;
    width: 100%;
    margin: auto;
    padding: 5em 16px 16px;

    h1 {
      font-size: 60px;
      font-weight: 300;
      line-height: 1;
      margin-top: 0;
      margin-bottom: 10px;
      color: #898885;
      font-family: "Raleway";
      text-align: center;

      span {
        display: block;
        font-size: 40px;
      }
    }

    p {
      color: #000;
      font-size: 16px;
    }

    .link-description-container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .link-description-containerA,
      .link-description-containerB {
        text-align: center;
        font-weight: 400;
        color: #000;
        line-height: 1.2em;
        font-size: 12px;
        background: #6062771a;
        padding: 16px 8px;
        border-radius: 4px;
        margin: 4px;
        text-decoration: none;
        display: block;
        cursor: default;

        .right-arrow-icon {
          color: #5e5c5a;
          vertical-align: middle;
          margin: 0 4px;
        }

        .link-img-icon {
          display: block;
          text-align: center;
          margin: 0 auto 4px;
          font-size: 24px;
          color: #5e5c5a;
        }
      }

      .link-description-containerA {

        &:hover,
        &:after {
          background: #549b9f94;
          text-decoration: none;
        }
      }

      .link-description-containerB {

        &:hover,
        &:after {
          background: #1a4c917a;
          text-decoration: none;
        }
      }
    }
  }
}

/*
=============== 
Section2. Work Sample
===============
*/

.mobile-worksample-container {
  display: none;
}

.pc-worksample {
  display: flex;
  height: auto;
  margin-bottom: 8em;
  width: auto;

  .nav-item-ttl-active {
    color: #606277 !important;
  }

  .vertical-nav {
    display: flex;
    flex-direction: column;
    width: 20px;
    height: 5px;
    justify-content: center;
    margin: auto 0;
  }

  .workSample-content {
    width: -webkit-fill-available;
  }
}

/*
=============== 
Section3. Biography
===============
*/
.profile-container {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  color: black;

  div:nth-child(1) {
    .tab-space {
      padding-left: 16px;
    }
  }

  // div:nth-child(2) {
  //   margin: 2em 1em 1em 1em;
  //   height: 150px;
  //   width: 150px;
  //   //background: url() no-repeat center;
  //   background-size: cover;
  //   overflow: hidden;
  //   border-radius: 50%;


  // }

}



.background-img-tech {
  position: relative;
  width: 100%;
  color: #000;
  overflow: hidden;

  &::before {
    background-image: url('../asset/img/background_technology.jpg');
    position: absolute;
    height: 350px;
    width: 70%;
    content: "";
    z-index: -1;
    background-size: 150%;
    background-position: -150px 9px;
    left: 40%;
    opacity: 0.4;
  }

  .dev-skills{
    transition: all 0.1s linear;
    background: linear-gradient(to right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: nav-animation 0.5s linear;
  }

  .skill-list-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr;

    b {
      font-weight: 600;
      color: #000;
      text-decoration: underline;
    }

    ul {
      margin: 5px 1.2em;
      padding: 0.2em;
      color: black;
    }

    div {
      font-size: 12px;
    }
  }
}

/*
=============== 
Section4. Contact
===============
*/
.contact-container {
  margin: 1em;

  .contact-input-container {
    .contact-email {
      font-family: 'raleway';
      padding: 4px;
      border-radius: 2px;
      border: 1px solid #7c7b7c;
      width: -webkit-fill-available;
    }

    .contact-message {
      margin: 4px 0;
      font-family: 'raleway';
      padding: 4px;
      border: 1px solid #7c7b7c;
      width: -webkit-fill-available;
    }

    .contact-submit-container {
      text-align: right;

      .contact-submit {
        width: 70px;
        border-radius: 0px;
        border: 1px #888784 solid;
        padding: 4px 2px;
        color: #888784;
        background: #fff;
        font-size: 12px;
        font-weight: bold;

        &:hover {
          background-color: #88878421;
        }
      }
    }
  }
}

/*
=============== 
Footer
===============
*/
footer {
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  bottom: 0;
  padding: 0.5em 0.2em 1em;
  margin-top: 5em;

  .left-block {
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .external-link {
    .icon_blog {
      &:hover {
        color: #434343;
      }
    }

    .icon_linkedin {
      &:hover {
        color: #017bb3;
      }
    }

    .icon_twitter {
      &:hover {
        color: #1cccfc;
      }
    }

    svg {
      color: #383838;
      font-size: 24px;
      margin: 4px;
      cursor: default;

      &:hover {
        transition: all 0.1s linear;
        transform: scale(1.2);
      }
    }
  }

  .copyright {
    margin: 0px;
    font-size: 12px;
    font-family: sans-serif;
  }

  .honourCode_PC {
    width: 500px;
    display: block;
    padding: 8px;
    font-family: sans-serif;

    h4 {
      margin: 0px;
      font-size: 12px;
      color: #7d7d7d;
      font-weight: bold;
    }

    .text {
      font-size: 12px;
    }
  }

  .honourCode_Phone {
    display: none;
  }
}
/*
=============== 
Tablet Size Responsive Setting 
===============
*/
@media (max-width:1024px) and (max-width: 768px) {
  .nav-container {
    grid-template-columns: auto;
    justify-content: center;
  }

  .section-title {
    margin-top: 1em;
  }
}
/*
=============== 
Phone Size Responsive Setting 
===============
*/

@media (max-width: 768px) {
  .header {
    .nav-container {
      display: flex;
      justify-content: space-between;
      margin-left: 10px;

      .site-logo-s {
        height: 30px;
        margin: 8px;
      }

      .nav-btn {
        display: inline-block;
        font-size: 35px;
        margin: 8px;
        border: none;
        background: none;
        color: grey;
      }

      .nav {
        display: none;
      }

      .banner-title {
        h1 {
          font-size: 30px;

          span {
            font-size: 20px;
          }
        }
      }
    }
  }

  .top-banner-container {
    margin-top: 1em;
  }

  .section-title {
    margin: 1em 0 .5em;
    line-height: 0.9em;
  }

  .react-swipeable-view-container div {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .mobile-worksample-container {
    display: block;

    &.css-19r6kue-MuiContainer-root {
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      height: auto;
    }

    .jFJUQD {
      padding: 0 0;
      font-size: 12px;
    }

    .tag-container {
      display: contents;
    }

    .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
      color: #606277;
    }

    .icon_selector_selected {
      color: #707070;
      font-size: 9px;
      display: block;
      padding: 4px;
    }

    .icon_selector {
      color: #d9d9d9;
      font-size: 9px;
      display: block;
      padding: 4px;
    }

    .right-arrow-icon {
      vertical-align: middle;
      margin: auto 0;
    }

    .sample-contents-container {
      display: flex;
      flex-direction: column;
    }

    .mobile-comment {
      font-family: arial;
      color: #3f51b5;
      font-weight: normal;
      vertical-align: middle;
      cursor:default;
    }
    .mobile-tmp-box-container{
      align-items: center;
      overflow-y: hidden;
      transition: transform 0.5s ease-out;
      position: relative;
      height: 500px;
      display: block;
    }
    .mobile-tmp-box {
      animation: .8s slide-right;
      animation-delay: 0s;
   }
    @keyframes slide-right {
      from {
         left: 0px;
      }
      to {
         left: 600px;
      }
   }

  }

  .pc-worksample {
    display: none !important;
  }

  .skill-list-container {
    grid-template-columns: auto !important;
    ;

    ul {
      list-style-type: none;
      border: none;
      border-style: none;
      background-color: #f9f9f9;
      margin: 5px 0.2em;
      padding: 1em;
    }
  }

  .background-img-tech {
    background: #ffffff96;

    &::before {
      height: 500px;
      width: 100%;
      left: 0%;
      background-size: 170%;
      background-position: -100px 9px;
    }
  }

  .css-xdmu94-MuiContainer-root {
    padding: 0 !important;
  }

  .profile-container {
    display: flex;
    flex-direction: column;
    margin: 0;

    div:first-child {
      padding: 0 4px;
      margin: 0;
      background-color: transparent;
    }
  }

  .contact-container {
    grid-template-columns: auto;
  }

  footer {
    padding: 0.5em 0.2em 0;
    flex-direction: column;

    .copyright {
      font-size: 8px;
    }

    .left-block {
      margin: 0 0 8px;
    }

    .external-link {
      svg {
        font-size: 12px;
      }
    }

    p {
      font-size: 7px;
      line-height: 1em;
      font-family: sans-serif;
    }

    .honourCode_PC {
      display: none !important;
    }

    .honourCode_Phone {
      display: block !important;
      padding: 0;
      margin: 0 0 4px;

      .text {
        font-size: 9px;
        line-height: 1em;
        font-family: sans-serif;
      }
    }
  }
}

