// sass --watch style.scss style.css
/*
=============== 
common
===============
*/
.sample-container-002 {
  font-size: 12px;

  .sample-title {
    font-size: 18px;
    font-weight: 300;
    margin: 16px 0 8px;
    color: #000;
  }

  .tag-container {
    text-align: left;
    display: inline;
    color: #000;
    font-weight: normal;
    cursor: default;
    width: max-content;

    .tech-tag {
      padding: 0px 12px;
      background: #D9D9D9;
      border-radius: 4px;
      font-size: 11px;
      margin: 0 4px;
      cursor: default;
      width: max-content;
      display: inline-block;

      &:hover {
        background: #8080808c;
      }
    }
  }

}

/*
  =============== 
   the worksample 
  ===============
  */
.sample-contents-container-002 {

  .sample-input-container {
    font-weight: normal;
    color: #000;
    font-size: 12px;
  }

  .table-container {
    font-weight: normal;
    color: #000;
    z-index: 1;

    .list-ttl {
      margin-bottom: 0px;
      font-weight: normal;
      font-size: 12px;
    }

    .result-data-container {
      display: grid;
      position: relative;
      width: fit-content;

      span {
        margin-right: 4px;
      }

      .contributors_list {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
        }

        .contributors_list_delete_icon {
          background: none;
          border: none;
        }
      }
    }
  }

  .login-tab {
    margin-top: 2em;
    display: flex;
    margin-left: 9px;
    font-size: 12px;
  }

  .login-tab button {
    margin: 0 -1px;
  }

  .login-tab button.login-tab-item-active {
    padding: 2px 4px;
  }

  .login-tab-item-active {
    border-bottom: 2px solid rgb(96 98 119) !important;
    margin: 0px 0px;
    font-weight: bold;
  }


  .login-controller-box {
    color:#000;
  }

  .login-display-box {
    background: rgb(249, 249, 249);
    padding: 1em 3em;
    vertical-align: middle;
    margin: .5em 0 auto;
    height: max-content;
    grid-template-columns: 1fr 1fr;
    display: grid;
    border: 2px solid #888784;
    border: 2px solid #458fc0;
    border-radius: 7px;
    p:nth-child(2) {
      border-left: solid 1px black;
      padding-left: 20px;
    }
  }
  .login-display-box2 {
    background: rgb(249, 249, 249);
    padding: 1em 3em;
    vertical-align: middle;
    margin: .5em 0 auto;
    height: max-content;
    width: fit-content;
    border: 2px solid #10ff97;
    border-radius: 7px;
  }
  .login-admin-container{
    font-size: 12px;
    .ttl{
      margin: 0 2em 1em;
      border-bottom: solid 1px;
      text-align: left;
      div {
        width: 150px;
        text-overflow: ellipsis;
        &:nth-child(1){
          width: 56px;
        }
        &:nth-child(7){
          width: 200px;
        }
      }
    }
    .item {
      margin: 0 3em 0;
      > div {
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:nth-child(1){
          width: 44px;
        }
        &:nth-child(7){
          width: 200px;
        }
        svg{
          font-size: 20px;
        }
      }
      .btn-sp{
        display: none
      }
    }
    .login-admin-container-user {
      .ttl{
        >div{
          width: 100px;
          &:nth-child(1){
            width: 35px;
          }
          &:nth-child(7){
            width: 200px;
          }
        }
      }
      .item{
        > div {
        width: 184px;
        &:nth-child(1){
          width: 32px;
        }
        &:nth-child(7){
          width: 350px;
        }
      }
      } 
    }
    .item-edit {
      margin: auto 3em;
      vertical-align: middle;
      div {
        width: 132px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: auto 0;
        &:nth-child(1){
          width: 44px;
        }
        &:nth-child(2){
          width: 120px;
        }
        &:nth-child(6){
          width: 110px;
          select{
            width: 86px;
          }
        }
        &:nth-child(7){
          width: 200px;
        }
        svg{
          font-size: 20px;
        }
        select {
          border: 1px solid #000;
          height: 22px;
          margin-bottom: 4px;
        }
        .inputA {
          border-radius: 0;
          height: 22px;
          border: 1px solid #000;
          width: 100px;
          margin-bottom: 4px;
          padding-left: 2px;
        }
        .inputB {
          border-radius: 0;
          height: 22px;
          border: 1px solid #000;
          width: 100px;
          margin-bottom: 4px;
          padding-left: 2px;
        }
      }
    }
  }
  .login-input-container,
  .create-new-account-input-container {
    flex-direction: column;
    display: flex;
  }

  .login-input-container-fit {
    width: fit-content;
  }

  .login-input-container,
  .create-new-account-input-container,
  .login-container {
    font-size: 16px;
    align-items: center;
    align-content: center;
    margin: auto;
    h4{
      margin: 0 3em 1em;
      font-weight: lighter;
      font-size: 16px;
    }
    form {
      text-align: center;
    }

    .login-input-container,
    .create-new-account-input-container {
      flex-direction: column;
      display: flex;
    }

    label {
      text-align: left;
    }

    svg {
      font-size: 14px;
      margin-right: 2px;
      display: inline;
      color: #606277;
    }

    p {
      font-size: 9px;
      cursor: default;
      margin: 0 1em;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    .login-input,
    .create-new-account-input {
      border-radius: 0;
      height: 38px;
      border: 1px solid #000;
      width: 230px;
      margin-bottom: 4px;
      padding-left: 12px;
    }

    .account-err,
    .create-new-account-err {
      font-size: 16px;
      color: #e4001d;
      line-height: 1em;
      display: inherit;
      vertical-align: text-top;
      margin-bottom: 4px;
      width: 250px;
      text-align: left;
    }

    .account-sub-msg {
      font-size: 10px;
      color: #000000;
      line-height: 1em;
      display: inherit;
      vertical-align: text-top;
      width: 250px;
      text-align: left;
      background: #0596003b;
      padding: 4px;
      margin: 4px 0;
    }
    .notification{
      font-size: 18px;
      margin: 8px 16px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      background: #fafafa;
      vertical-align: middle;
      padding: 6px 12px;
      div{
        margin: auto 0;
      }
    }
    // .account-msg,
    // .create-new-account-msg {
    //   font-size: 10px;
    //   color: #fff;
    //   line-height: 2.2em;
    //   display: inherit;
    //   vertical-align: text-top;
    //   background: #009688;
    //   font-weight: bold;
    //   padding: 0 8px;
    //   margin-bottom: 4px;
    // }
    .account-err-msg {
      font-size: 18px;
      padding: 8px;
      margin: 8px 16px;
      border: 2px solid red;
      border-radius: 8px;
      background: rgb(222 107 107 / 18%);
      color: red;
      div{ 
        margin: auto 0;
      }
    }
    .notification-container {
      svg {
        font-size: 34px;
        margin-right: 2px;
      } 
    }

    .login-contents-container {
      margin: 1em;

      form {
        text-align: left;
      }

      .menu-item {
        display: flex;
        flex-direction: initial;
        justify-content: space-between;
        align-items: center;
      }
    }

    .login-container-txt {
      margin: 1em;
    }

    .login-nav {
      display: flex;
      justify-content: space-between;
      flex-direction: inherit;
      // margin: 0 1em;
      margin: 0 1em 1em;
      border-bottom: 1px solid #000;
      span {
        background: #cdcdcd;
        border-radius: 2px;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 4px 2px;
        padding: 2px 7px 0;
        vertical-align: middle;
        font-size: 12px;
      }
      div:nth-child(2){
        font-size: 10px;
      }
      .logout-container{
        border-radius: 4px;
        padding: 4px 8px;
        margin-bottom: 5px;
        border: 1px solid #000;
      }
    }

    .link-btn {
      cursor: default;
      display: flex;
      font-size: 16px;
      border: none;
      background: none;
      text-decoration: underline;
      font-weight: lighter;
      line-height: 1.4em;
    }

    .logout-btn {
      width: fit-content;
      background: none;
      padding: 4px 8px;
      font-size: 12px;
      border: 0px;
      &:disabled {
        border: 1px solid #c4c4c4;

        &:hover,
        &:active {
          background: none;
        }
      }

      &:hover,
      &:active {
        background: #D9D9D9;
      }
      svg {
          vertical-align: text-bottom;
          font-size: 16px;
          margin: auto 2px;
      }
      
    }
    .login-btn,
    .register-btn
    // .update2-btn
    {
      width: fit-content;
      padding: 4px 8px;
      font-size: 16px;
      border-radius: 4px;
      background: #000;
      color: white;

      &:disabled {
        border: 1px solid #c4c4c4;
        background: #D9D9D9;

        &:hover,
        &:active {
          border: 1px solid #c4c4c4;
          background: #D9D9D9;
        }
      }

      &:hover,
      &:active {
        background: #000000cc;
      }

      .login-btn-link {
        text-decoration: none;
        color: white;
      }
    }
    .edit-btn,
    .update2-btn,
    .cancel-btn,
    .delete2-btn
    {
      width: fit-content;
      padding: 0px 16px;
      height: 18px;
      font-size: 12px;
      margin: 2px;
      color: #000;
      border: 1px solid #555;
      &:disabled {
        border: 1px solid #c4c4c4;
        background: #D9D9D9;
        color: grey;
        &:hover,
        &:active {
          border: 1px solid #c4c4c4;
          background: #D9D9D9;
        }
      }

      &:hover,
      &:active {
        background: #D9D9D9;
      }
    }
    .signin-btn,
    .delete-btn,
    .update-btn {
      width: fit-content;
      background: none;
      padding: 4px 8px;
      font-size: 10px;

      &:disabled {
        border: 1px solid #c4c4c4;

        &:hover,
        &:active {
          background: none;
        }
      }

      &:hover,
      &:active {
        background: #D9D9D9;
      }

      .login-btn-link {
        text-decoration: none;
        color: black;
      }
    }
    .delete-btn{
      border: 2px solid #dd2121;
      background: #dd0046;
      font-size: 16px;
      border-radius: 4px;
      color: white;
      font-weight: bold;
      &:hover,
      &:active {
        background: rgb(182 28 28 / 89%);
      }

    }
    .update-btn{
      border: 2px solid #009c26;
      background: #00dd34;
      font-size: 16px;
      border-radius: 4px;
      color: white;
      font-weight: bold;
      &:disabled {
        background: #c4c4c4;
        &:hover,
        &:active {
          background: rgb(31 135 103 / 89%);
        }
      }
    }
    .google-login-btn {
      margin: 5px 0;
      border: 1px solid #c4c4c4;
      font-size: 12px;
      vertical-align: -webkit-baseline-middle;
      align-items: flex-end;
      height: 29px;
      vertical-align: top;

      svg {
        font-size: 16px;
        margin: auto 2px;
      }
    }
    .login-input-ttl {
      text-align: left;
      margin-bottom: 16px;

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
    .login-input-txt {
      text-align: left;
      margin-bottom: 8px;
    }
  }
}


@keyframes slide {
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slide {
  100% {
    -webkit-transform: translateY(0%);
  }
}

/*
  =============== 
  Phone Size Responsive Setting 
  ===============
  */
@media (max-width: 768px) {
  .sample-container-002 {
    padding: 0;

    .tag-container {
      display: initial;
    }
  }

  .sample-contents-container-002 {
    display: block;



    .login-controller-box {
      width: auto;
    }

    .login-admin-container{
      .ttl{
        margin: 0 4px 1em;
      }
      .item{
        margin: 0 4px 1em;
        
        .btn-pc{
          display: none;
        }
          .btn-sp{
            display: flex;
        }
        .edit2-btn-bg, 
        .delete3-btn-bg {
            position: relative;
            background:rgb(211, 20, 103);
            background: linear-gradient(to right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
            cursor:default;
            background-size: 200% 100%;
            background-position: 100%;
            transition: background-position 275ms ease;
            animation-iteration-count: infinite;
            animation-duration: 275ms;
            height: 16px;
            width: 16px;
        }
        .edit2-btn,
        .delete3-btn{
          height: auto;
          width: auto;
          //mask-size: cover; *for bigger icon 
          &:hover{
            .edit2-btn-bg, 
            .delete3-btn-bg {
              background-position: 0 100%;
            }
            }
        }
      }
        .item-edit{
            display:block;
        }
    }
    .login-container{
      h4,.login-nav {
      margin: 0 4px 1em;
    }
    } 
  }
}

/*
  =============== 
  Tablet Size Responsive Setting 
  ===============
  */
//   @media (max-with:1024px) and (max-width: 768px) {
//   }