// sass --watch style.scss style.css
/*
=============== 
common
===============
*/
.sample-container{
    padding: 0 20px;
    font-size: 12px;
    .sample-title {
        font-size: 18px;
        font-weight: 300;
        margin: 16px 0 8px;
        color: #000;
      }
      .sample-title-container {
        .tag-container {
          text-align: right;
          display: inline-flex;
          color: #000;
          font-weight: normal;
          .tech-tag {
            padding: 0px 12px;
            background: #D9D9D9;
            border-radius: 4px;
            font-size: 11px;
            margin: 0 4px;
            cursor: default;
            &:hover {
              background: #8080808c;
            }
          }
        }  
        }
  }
      
  /*
  =============== 
   the worksample 
  ===============
  */
  .sample-contents-container-007 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .sample-input-container {
      font-family: sans-serif;
      font-weight: normal;
      color: #000;
      font-size: 12px;
    }
    .table-container{
      font-weight: normal;
      color: #000;
      z-index: 1;
      .list-ttl {
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 12px;
      }
      // .result-data-container{
      //   display: grid;
      //   grid-template-columns: 2fr 1fr;
      //   position: relative;
      //   top: -40px;
      //   span {
      //     margin-right: 4px;
      //   }
      // }
    }
  }
  
  /*
  =============== 
  Phone Size Responsive Setting 
  ===============
  */
  @media (max-width: 768px) {
    .sample-container{
      padding: 0;
    }
    .sample-contents-container-007 {
      display: block;
    }
  }
  /*
  =============== 
  Tablet Size Responsive Setting 
  ===============
  */
  //   @media (max-with:1024px) and (max-width: 768px) {
  //   }