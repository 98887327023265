// sass --watch style.scss style.css
/*
=============== 
common
===============
*/
.sample-container {
  padding: 0 20px;
  font-size: 12px;

  .sample-title {
    font-size: 18px;
    font-weight: 300;
    margin: 16px 0 8px;
    color: #000;
  }

  .sample-title-container {
    .tag-container {
      text-align: right;
      display: inline-flex;
      color: #000;
      font-weight: normal;

      .tech-tag {
        padding: 0px 12px;
        background: #D9D9D9;
        border-radius: 4px;
        font-size: 11px;
        margin: 0 4px;
        cursor: default;

        &:hover {
          background: #8080808c;
        }
      }
    }
  }
}

/*
=============== 
 the worksample 
===============
*/
.sample-contents-container-005 {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .sample-input-container {
    font-weight: normal;
    color: #000;
    font-size: 12px;

    .placeholder {
      color: black;
      font-size: 13px;
      font-family: inherit;
      font-weight: lighter;
      padding-left: 4px;
    }

    .piedata-input-container {
      border-radius: 0;
      height: 25px;
      width: 230px;
      margin-bottom: 4px;
      padding-left: 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      font-weight: normal;
      font-family: inherit;
    }

    .piedata-select-container {
      border-radius: 0;
      height: 30px;
      margin-bottom: 8px;
      width: 245px;
      font-size: 12px;
    }

    .piedata-btn-container {
      text-align: right;

      .piedata-btn {
        width: fit-content;
        background: none;
        padding: 4px 8px;
        font-size: 10px;
        border: 1px solid #000;

        &:disabled {
          border: 1px solid #c4c4c4;

          &:hover,
          &:active {
            background: none;
          }
        }

        &:hover,
        &:active {
          background: #D9D9D9;
        }
      }
      .hidden-message {
      svg {
              display: inline-block;
            }
      }
    }
  }

  .table-container {
    font-weight: normal;
    color: #000;
    z-index: 1;

    .list-ttl {
      margin-bottom: 0px;
      font-weight: normal;
      font-size: 12px;
    }

    .pie-label {
      font-size: 11px;
      font-family: sans-serif;
    }

    .recharts-wrapper {
      text-align: center;
      margin: auto;

      &::before {
        border-bottom: 1px solid black;
        width: 250px;
        z-index: 2;
        top: 100px;
        right: 20px;
        position: relative;
        display: block;
        content: "";
      }

      &::after {
        border-right: 1px solid black;
        height: 250px;
        z-index: 3;
        bottom: 220px;
        right: 99px;
        position: relative;
        display: block;
        content: "";
      }
    }

    svg.recharts-surface {
      transform: scale(1.1);
    }

    path.recharts-sector {
      stroke: none;
    }

    .result-data-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      position: relative;
      top: -40px;

      span {
        margin-right: 4px;
      }

      .contributors_list {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
        }

        .contributors_list_delete_icon {
          background: none;
          border: none;
        }
      }
    }
  }
}

/*
=============== 
Phone Size Responsive Setting 
===============
*/
@media (max-width: 768px) {
  .sample-container {
    padding: 0;
  }

  .sample-contents-container-005 {
    display: block;
  }
}

/*
=============== 
Tablet Size Responsive Setting 
===============
*/
//   @media (max-with:1024px) and (max-width: 768px) {
//   }