//sass --watch style.scss style.css

/*
=============== 
common
===============
*/
.sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}

.sample-data {
  font-family: Arial, Helvetica, sans-serif;
}

.tag-container {
  text-align: left;
  display: inline-flex;
  color: #000;
  font-weight: normal;
  width: max-content;

  .tech-tag {
    padding: 0px 12px;
    background: #D9D9D9;
    border-radius: 4px;
    font-size: 11px;
    margin: 0 4px;
    height: 16px;
    cursor: default;

    &:hover {
      background: #8080808c;
    }
  }
}

.sample-title-container-006 {
  font-weight: normal;
  color: black;

  .table-title-container {
    border-radius: 0;
    font-family: sans-serif;
    font-weight: 200;
    height: 30px;
    color: #000;
    font-size: 12px;
  }
}

/*
  =============== 
  the worksample 
  ===============
  */

.sample-contents-container-006 {
  display: grid;
  grid-template-columns: 200px 4fr;

  .sample-title-container-006 {
    .select-field {
      width: auto;
      // display: flex !important;
      // border: solid 1px #c4c4c4;
      // padding: 8px 4px;
    }

    .update-btn {
      border: 1px solid #666;
      padding: 2px 4px;
      margin: 8px 0;
      border-radius: 4px;
      background: #000;
      color: white;

      &:hover,
      &:active {
        background: #000000c9;
      }

      &:disabled {
        cursor: default;
        background: #d9d9d9;
        border: none;
      }
    }
  }

  .sample-table-container-006 {
    justify-content: center;
    margin: 20px 0 20px 20px;

    h4 {
      color: #000;
      font-weight: normal;
    }
    .rate-tab{
        display: flex;
        justify-content: space-evenly;
        margin: 0 35%;
        button{
          padding: 0px 8px;
        }
        .term-ttl-active{
          background: #b2b2b03b;
          padding: 0px 8px;
          border-radius: 8px;
        }
    }
    .graph-container {

      .recharts-layer {
        font-size: 8px;
      }
      .line-graph-pc {
        display: block;
      }

      .line-graph-sp {
        display: none;
      }

      .line-graph-pc,
      .line-graph-sp {

        .hover-label {
          background-color: #fff;
          border: 1px black solid;
          font-weight: normal;
          font-family: sans-serif;
          font-size: 9px;
          padding: 0px 2px;
          height: fit-content;
          border-radius: 0;

          &:hover {
            border-radius: 0;
          }

          p {
            padding: 0;
          }
        }
      }
    }
  }
}


@keyframes bar-animation {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*
  =============== 
  Phone Size Responsive Setting 
  ===============
  */
@media (max-width: 768px) {
  .sample-contents-container-006 {
    display: block;

    .tag-container {
      display: contents;
    }

    .sample-title-container-006 {
      padding: 0 0;

      .sample-title {
        padding: 0 0;
      }

    }

    .sample-table-container-006 {
      margin: 0;

      h4 {
        padding: 0 0;
      }

      .graph-container {
        margin: 0;
        height: 150px;
        font-size: 9px;

        .line-graph-pc {
          display: none;
        }

        .line-graph-sp {
          display: block;

          svg.recharts-surface {
            position: absolute;
            left: -40px;
            overflow: hidden;
          }
        }
      }
    }

  }
}

/*
  =============== 
  Tablet Size Responsive Setting 
  ===============
  */
// @media (min-width: 768px) and (max-width:1024px) {
//   .sample-contents-container-006 {
//     .sample-title-container-006 {
//     }
//   }
// }