// sass --watch style.scss style.css
/*
=============== 
common
===============
*/
.sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}

.tag-container {
  text-align: right;
  display: inline-flex;
  color: #000;
  font-weight: normal;

  .tech-tag {
    padding: 0px 12px;
    background: #D9D9D9;
    border-radius: 4px;
    font-size: 11px;
    margin: 0 4px;
    cursor: default;

    &:hover {
      background: #8080808c;
    }
  }

  .table-title-container {
    border-radius: 0;
    font-family: sans-serif;
    font-weight: 200;
    height: 30px;
    color: #000;
    font-size: 12px;
  }

  .select-field {
    width: 550px;
    color: #000;
    font-weight: normal;
  }
}

/*
=============== 
the worksample 
===============
*/
.sample-contents-container {
  .search-filter-part1 {
    margin: 4em 1em 2em;
    padding: 1em 1.5em;
    width: fit-content;
    border-radius: 4px;
    border: 1px solid;
    font-family: sans-serif;
    font-weight: normal;
    color: #000;
    font-size: 12px;

    div {
      margin: 8px 0 4px;
    }
  }

  .table-container {
    font-weight: normal;
    color: #000;
    cursor: default;

    .list-ttl {
      text-align: center;
    }

    .movie_table {
      .ttl-li {
        display: flex;

        .ttl {
          &-lg {
            width: 59.5%;
          }

          &-sm {
            width: 20.5%;
          }
        }

        span {
          padding: 0;
        }
      }

      .item-li {
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        .item {
          &-lg {
            width: 60%;
            padding: 0 5px;
            white-space: nowrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
              color: inherit;
            }
          }

          &-sm {
            width: 20%;
            padding: 0 5px;
            white-space: nowrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
              color: inherit;
            }
          }
        }
      }
    }

    p {
      text-align: right;
    }

    .order-btn {
      color: #1976d2;
    }
  }
}

@media (max-width: 768px) {
  .sample-contents-container {
    .search-filter-part1 {
      margin: 1em 0 0;
      padding: 0;
      height: auto;
      width: auto;
      border: none;

      div {
        margin: 0;
        font-size: 9px;
      }
    }

    .table-container {
      .list-ttl {
        margin: 4px 0;
      }

      .movie_table {
        padding: 0px;
        margin: 0px;

        .ttl-li {
          font-size: 9px;
          display: initial;

          .ttl {
            &-lg {
              width: 100%;
            }

            &-sm {
              width: 100%;
            }
          }
        }

        .item-li {
          display: block;

          span {
            padding: 0 !important;
          }
        }
      }
    }
  }
}