/*
=============== 
common
===============
*/
.sample-container {
  padding: 0 20px;
  font-size: 12px;
}
.sample-container .sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}
.sample-container .sample-title-container .tag-container {
  text-align: right;
  display: inline-flex;
  color: #000;
  font-weight: normal;
}
.sample-container .sample-title-container .tag-container .tech-tag {
  padding: 0px 12px;
  background: #D9D9D9;
  border-radius: 4px;
  font-size: 11px;
  margin: 0 4px;
  cursor: default;
}
.sample-container .sample-title-container .tag-container .tech-tag:hover {
  background: rgba(128, 128, 128, 0.5490196078);
}

/*
=============== 
 the worksample 
===============
*/
.sample-contents-container-005 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.sample-contents-container-005 .sample-input-container {
  font-weight: normal;
  color: #000;
  font-size: 12px;
}
.sample-contents-container-005 .sample-input-container .placeholder {
  color: black;
  font-size: 13px;
  font-family: inherit;
  font-weight: lighter;
  padding-left: 4px;
}
.sample-contents-container-005 .sample-input-container .piedata-input-container {
  border-radius: 0;
  height: 25px;
  width: 230px;
  margin-bottom: 4px;
  padding-left: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-weight: normal;
  font-family: inherit;
}
.sample-contents-container-005 .sample-input-container .piedata-select-container {
  border-radius: 0;
  height: 30px;
  margin-bottom: 8px;
  width: 245px;
  font-size: 12px;
}
.sample-contents-container-005 .sample-input-container .piedata-btn-container {
  text-align: right;
}
.sample-contents-container-005 .sample-input-container .piedata-btn-container .piedata-btn {
  width: fit-content;
  background: none;
  padding: 4px 8px;
  font-size: 10px;
  border: 1px solid #000;
}
.sample-contents-container-005 .sample-input-container .piedata-btn-container .piedata-btn:disabled {
  border: 1px solid #c4c4c4;
}
.sample-contents-container-005 .sample-input-container .piedata-btn-container .piedata-btn:disabled:hover, .sample-contents-container-005 .sample-input-container .piedata-btn-container .piedata-btn:disabled:active {
  background: none;
}
.sample-contents-container-005 .sample-input-container .piedata-btn-container .piedata-btn:hover, .sample-contents-container-005 .sample-input-container .piedata-btn-container .piedata-btn:active {
  background: #D9D9D9;
}
.sample-contents-container-005 .sample-input-container .piedata-btn-container .hidden-message svg {
  display: inline-block;
}
.sample-contents-container-005 .table-container {
  font-weight: normal;
  color: #000;
  z-index: 1;
}
.sample-contents-container-005 .table-container .list-ttl {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 12px;
}
.sample-contents-container-005 .table-container .pie-label {
  font-size: 11px;
  font-family: sans-serif;
}
.sample-contents-container-005 .table-container .recharts-wrapper {
  text-align: center;
  margin: auto;
}
.sample-contents-container-005 .table-container .recharts-wrapper::before {
  border-bottom: 1px solid black;
  width: 250px;
  z-index: 2;
  top: 100px;
  right: 20px;
  position: relative;
  display: block;
  content: "";
}
.sample-contents-container-005 .table-container .recharts-wrapper::after {
  border-right: 1px solid black;
  height: 250px;
  z-index: 3;
  bottom: 220px;
  right: 99px;
  position: relative;
  display: block;
  content: "";
}
.sample-contents-container-005 .table-container svg.recharts-surface {
  transform: scale(1.1);
}
.sample-contents-container-005 .table-container path.recharts-sector {
  stroke: none;
}
.sample-contents-container-005 .table-container .result-data-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
  top: -40px;
}
.sample-contents-container-005 .table-container .result-data-container span {
  margin-right: 4px;
}
.sample-contents-container-005 .table-container .result-data-container .contributors_list {
  margin: 0;
  padding: 0;
}
.sample-contents-container-005 .table-container .result-data-container .contributors_list li {
  list-style: none;
}
.sample-contents-container-005 .table-container .result-data-container .contributors_list .contributors_list_delete_icon {
  background: none;
  border: none;
}

/*
=============== 
Phone Size Responsive Setting 
===============
*/
@media (max-width: 768px) {
  .sample-container {
    padding: 0;
  }
  .sample-contents-container-005 {
    display: block;
  }
}
/*
=============== 
Tablet Size Responsive Setting 
===============
*/

