/*
=============== 
common
===============
*/
.sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}

.sample-title-container-004 {
  font-weight: normal;
  color: black;
}
.sample-title-container-004 .tag-container {
  text-align: right;
  display: inline-flex;
  color: #000;
  font-weight: normal;
}
.sample-title-container-004 .tag-container .tech-tag {
  padding: 0px 12px;
  background: #D9D9D9;
  border-radius: 4px;
  font-size: 11px;
  margin: 0 4px;
  height: fit-content;
  cursor: default;
}
.sample-title-container-004 .tag-container .tech-tag:hover {
  background: rgba(128, 128, 128, 0.5490196078);
}
.sample-title-container-004 .table-title-container {
  border-radius: 0;
  font-family: sans-serif;
  font-weight: 200;
  height: 30px;
  color: #000;
  font-size: 12px;
}

/*
=============== 
the worksample 
===============
*/
.sample-contents-container-004 .sample-title-container .select-field {
  margin: 12px 0;
}
.sample-contents-container-004 .table-container {
  display: flex;
  width: -webkit-fill-available;
  font-family: raleway;
  color: black;
  font-size: 12px;
  font-weight: normal;
}
.sample-contents-container-004 .table-container h4 {
  color: #000;
  font-weight: normal;
}
.sample-contents-container-004 .table-container .select-field {
  margin: 24px 100px 8px 0;
}
.sample-contents-container-004 .table-container .select-field .table-title-container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 30px;
}
.sample-contents-container-004 .table-container .graph-container {
  display: inline-flex;
  text-align: center;
  margin: 0 auto;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines {
  position: relative;
  z-index: 2;
  display: block;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line {
  text-align: right;
  position: absolute;
  font-size: 7px;
  font-weight: normal;
  width: 60px;
  left: -26px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line.index0 {
  top: 223px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line.index1 {
  top: 183px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line.index2 {
  top: 137px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line.index3 {
  top: 91px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line.index4 {
  top: 45px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line.index5 {
  top: -1px;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line .label {
  display: inline-block;
  font-size: 8px;
  vertical-align: top;
}
.sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines .graph-horizontal-line .tick {
  color: #646464;
  display: inline-block;
  border: solid 1px;
  padding-left: 4px;
  margin-left: 2px;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars {
  font-family: system-ui;
  display: flex;
  border-bottom: 1px solid;
  border-left: 1px solid;
  line-height: 0px;
  justify-content: center;
  width: 100px;
  text-align: center;
  margin: 0 0 0 34px;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars > div {
  position: relative;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress-hover-label-container {
  opacity: 0;
  font-size: 9px;
  z-index: 3;
  position: absolute;
  cursor: default;
  height: 100%;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress-hover-label-container:hover {
  opacity: 1;
  color: black;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress-hover-label-container .progress-hover-label {
  background-color: white;
  padding: 6px 4px 0px 4px;
  border: 1px black solid;
  font-weight: normal;
  height: 14px;
  width: max-content;
  position: relative;
  display: block;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .sr-only {
  display: block;
  line-height: 1em;
  font-size: 8px;
  display: none;
  position: absolute;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress {
  height: 20px;
  margin-bottom: 0px;
  overflow: hidden;
  background-color: #f5f5f5;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress.vertical {
  position: relative;
  width: 10px;
  height: 230px;
  display: inline-block;
  margin-right: 10px;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress.vertical .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
  float: left;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #606277;
  height: 0;
  animation: bar-animation 1.5s ease-in-out forwards;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress:hover {
  box-shadow: 0px 0px 4px #1976d2, 0 0 4px #1a4c91;
}
.sample-contents-container-004 .table-container .graph-container .graph-bars .progress-label {
  vertical-align: middle;
  color: #000;
  font-size: 7px;
  z-index: 2;
  position: absolute;
  padding: 4px 1px;
  font-weight: normal;
  margin: 0;
  bottom: -12px;
}
.sample-contents-container-004 .table-container .graph-container p {
  text-align: right;
}

@keyframes bar-animation {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
=============== 
Phone Size Responsive Setting 
===============
*/
@media (max-width: 768px) {
  .sample-contents-container-004 {
    display: block;
  }
  .sample-contents-container-004 .sample-title-container-004 .select-field {
    width: auto;
    margin: 12px 0 4px;
    display: flex !important;
  }
  .sample-contents-container-004 .css-dyty4r-MuiFormControl-root {
    width: 300px;
  }
  .sample-contents-container-004 .table-container {
    flex-direction: column;
  }
  .sample-contents-container-004 .table-container .graph-container {
    display: block;
  }
  .sample-contents-container-004 .table-container .graph-container .graph-bars {
    margin: auto;
  }
  .sample-contents-container-004 .table-container .graph-container .graph-horizontal-lines {
    width: 100px;
    margin: auto;
    right: 34px;
  }
  .sample-contents-container-004 .table-container .graph-container .graph-horizontal-line {
    left: -160px;
    position: relative;
    margin: inherit;
  }
  .sample-contents-container-004 .table-container .graph-container .graph-horizontal-line.index0 {
    top: 224px;
  }
  .sample-contents-container-004 .table-container > div:nth-child(1) {
    order: 2;
  }
  .sample-contents-container-004 .progress-label {
    margin: 0 0 -4px 0;
    bottom: -5px;
  }
}
/*
=============== 
Tablet Size Responsive Setting 
===============
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .sample-contents-container-004 .sample-title-container-004 .select-field {
    width: fit-content;
  }
  .sample-contents-container-004 .table-container {
    margin-left: 0px;
  }
  .sample-contents-container-004 .table-container .progress-label {
    bottom: -284px;
  }
}

