/*
=============== 
common
===============
*/
.sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}

.tag-container {
  text-align: right;
  display: inline-flex;
  color: #000;
  font-weight: normal;
}
.tag-container .tech-tag {
  padding: 0px 12px;
  background: #D9D9D9;
  border-radius: 4px;
  font-size: 11px;
  margin: 0 4px;
  cursor: default;
}
.tag-container .tech-tag:hover {
  background: rgba(128, 128, 128, 0.5490196078);
}
.tag-container .table-title-container {
  border-radius: 0;
  font-family: sans-serif;
  font-weight: 200;
  height: 30px;
  color: #000;
  font-size: 12px;
}
.tag-container .select-field {
  width: 550px;
  color: #000;
  font-weight: normal;
}

/*
=============== 
the worksample 
===============
*/
.sample-title-container {
  display: inline-block;
  color: black;
}

.sample-contents-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.sample-contents-container .search-filter-part2 {
  margin: 4em 1em 2em;
  padding: 1em 1.5em 2em;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid;
  font-weight: normal;
  color: #000;
  font-size: 12px;
  font-family: sans-serif;
}
.sample-contents-container .search-filter-part2 div {
  margin: 8px 0 4px;
}
.sample-contents-container .search-filter-part2 .search-bar {
  border: none;
  border: 1px solid #888784;
  background-color: transparent;
  font-size: 12px;
}
.sample-contents-container .search-filter-part2 .range-filter {
  color: #606277;
  border-radius: 1px;
  padding: 0;
  height: 2px;
  margin: 0 4px;
}
.sample-contents-container .search-filter-part2 .range-filter .MuiSlider-thumb {
  width: 9px;
  height: 9px;
  box-shadow: none;
  color: #606277;
}
.sample-contents-container .search-filter-part2 .range-filter .MuiSlider-track {
  border: none;
}
.sample-contents-container .table-container {
  font-weight: normal;
  color: #000;
  cursor: default;
}
.sample-contents-container .table-container .list-ttl {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  font-family: "raleway";
}
.sample-contents-container .table-container .movie_item {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.sample-contents-container .table-container p {
  text-align: right;
}

@media (max-width: 768px) {
  .sample-contents-container .search-filter-part2 {
    margin: 1em 0 0;
    padding: 0;
    height: auto;
    width: auto;
    border: none;
  }
  .sample-contents-container .search-filter-part2 div {
    margin: 8px 0 0;
    font-size: 9px;
  }
  .sample-contents-container .table-container .movie_item {
    display: block;
  }
}

