@tailwind base;
@tailwind components;
@tailwind utilities;
/*
=============== 
Properties
===============
*/
@font-face {
  font-family: "Caveat";
  font-style: normal;
  font-weight: normal;
  src: url("../asset/font/Caveat-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  src: url("../asset/font/Raleway-Light.ttf") format("truetype");
}
body {
  font-family: raleway;
  color: #888784;
  margin: 0;
  padding: 0;
  font-weight: 100;
  overflow-x: hidden;
}

h2 {
  color: #000;
  font-size: 24px;
  font-weight: 300;
}

h4 {
  color: #000;
  font-weight: normal;
}

.section-title {
  font-size: 40px;
  font-family: "raleway";
  color: #888784;
  margin-bottom: 0;
  font-weight: 300;
  margin-top: 2em;
}

/*
=============== 
Header and Navigation
===============
*/
.header {
  position: sticky;
  z-index: 2;
  top: 0;
  background: linear-gradient(rgba(229, 239, 254, 0.26), rgba(229, 252, 254, 0)) 50% 50%/cover;
  border-bottom: 1px solid #888784;
  background: white;
}
.header .nav-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
}
.header .nav-container h1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  margin: 10px 0;
  color: #898885;
  font-family: "Raleway";
  text-align: left;
  width: max-content;
}
.header .nav-container h1 span {
  font-weight: 300;
  display: block;
  font-size: 16px;
}
.header .nav-container .site-logo-s {
  height: 34px;
  margin: 10px;
}
.header .nav-container .nav-btn {
  display: none;
}
.header .nav-container .nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header .nav-container .nav .active {
  text-decoration: none;
  color: #888784;
  font-weight: 400;
}
.header .nav-container .nav-page-ttl-active {
  transition: all 0.1s linear;
  background: linear-gradient(to right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: nav-animation 0.5s linear;
}
@keyframes nav-animation {
  to {
    background: linear-gradient(right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: 50px;
  }
}
.header .nav-container .nav-page-ttl-active:active {
  text-decoration: none;
}

.sp-nav-container {
  top: 15%;
  position: fixed;
}
.sp-nav-container .nav-item {
  text-align: center;
  display: block;
  text-decoration: none;
  color: #888784;
  margin: 2em auto;
  cursor: default;
  font-weight: 400;
  font-family: raleway;
  font-size: 18px;
}
.sp-nav-container .nav-item:hover {
  color: #888784;
  transition: all 0.1s linear;
  transform: scale(1.1);
}
.sp-nav-container .nav-item:active {
  text-decoration: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  opacity: 0;
  transition: all 0.3s linear;
  transform: translateX(-100%);
}

.show-sidebar {
  opacity: 1;
  transform: translateX(0);
  background: #f1f5f8;
}

.close-btn {
  position: absolute;
  right: 4.75%;
  top: 2.75%;
  font-size: 2.5rem;
  background: none;
  border: none;
  color: #888784;
  cursor: pointer;
  font-size: 24px;
  font-weight: lighter;
  font-family: inherit;
}

@keyframes spNabMenuvAnimation {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/*
=============== 
Section1. Hero
===============
*/
.first-section {
  margin-top: 4em;
}

.top-banner-container .top-banner {
  width: 100%;
}
.top-banner-container .top-banner-blur {
  z-index: 3;
  position: absolute;
  top: 150px;
  left: 25%;
  background: rgba(255, 255, 255, 0.768627451);
  text-align: center;
  height: 50%;
}
.top-banner-container .banner-title {
  z-index: 4;
  top: 10%;
  width: 100%;
  margin: auto;
  padding: 5em 16px 16px;
}
.top-banner-container .banner-title h1 {
  font-size: 60px;
  font-weight: 300;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 10px;
  color: #898885;
  font-family: "Raleway";
  text-align: center;
}
.top-banner-container .banner-title h1 span {
  display: block;
  font-size: 40px;
}
.top-banner-container .banner-title p {
  color: #000;
  font-size: 16px;
}
.top-banner-container .banner-title .link-description-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.top-banner-container .banner-title .link-description-container .link-description-containerA,
.top-banner-container .banner-title .link-description-container .link-description-containerB {
  text-align: center;
  font-weight: 400;
  color: #000;
  line-height: 1.2em;
  font-size: 12px;
  background: rgba(96, 98, 119, 0.1019607843);
  padding: 16px 8px;
  border-radius: 4px;
  margin: 4px;
  text-decoration: none;
  display: block;
  cursor: default;
}
.top-banner-container .banner-title .link-description-container .link-description-containerA .right-arrow-icon,
.top-banner-container .banner-title .link-description-container .link-description-containerB .right-arrow-icon {
  color: #5e5c5a;
  vertical-align: middle;
  margin: 0 4px;
}
.top-banner-container .banner-title .link-description-container .link-description-containerA .link-img-icon,
.top-banner-container .banner-title .link-description-container .link-description-containerB .link-img-icon {
  display: block;
  text-align: center;
  margin: 0 auto 4px;
  font-size: 24px;
  color: #5e5c5a;
}
.top-banner-container .banner-title .link-description-container .link-description-containerA:hover, .top-banner-container .banner-title .link-description-container .link-description-containerA:after {
  background: rgba(84, 155, 159, 0.5803921569);
  text-decoration: none;
}
.top-banner-container .banner-title .link-description-container .link-description-containerB:hover, .top-banner-container .banner-title .link-description-container .link-description-containerB:after {
  background: rgba(26, 76, 145, 0.4784313725);
  text-decoration: none;
}

/*
=============== 
Section2. Work Sample
===============
*/
.mobile-worksample-container {
  display: none;
}

.pc-worksample {
  display: flex;
  height: auto;
  margin-bottom: 8em;
  width: auto;
}
.pc-worksample .nav-item-ttl-active {
  color: #606277 !important;
}
.pc-worksample .vertical-nav {
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 5px;
  justify-content: center;
  margin: auto 0;
}
.pc-worksample .workSample-content {
  width: -webkit-fill-available;
}

/*
=============== 
Section3. Biography
===============
*/
.profile-container {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  color: black;
}
.profile-container div:nth-child(1) .tab-space {
  padding-left: 16px;
}

.background-img-tech {
  position: relative;
  width: 100%;
  color: #000;
  overflow: hidden;
}
.background-img-tech::before {
  background-image: url("../asset/img/background_technology.jpg");
  position: absolute;
  height: 350px;
  width: 70%;
  content: "";
  z-index: -1;
  background-size: 150%;
  background-position: -150px 9px;
  left: 40%;
  opacity: 0.4;
}
.background-img-tech .dev-skills {
  transition: all 0.1s linear;
  background: linear-gradient(to right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: nav-animation 0.5s linear;
}
.background-img-tech .skill-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr;
}
.background-img-tech .skill-list-container b {
  font-weight: 600;
  color: #000;
  text-decoration: underline;
}
.background-img-tech .skill-list-container ul {
  margin: 5px 1.2em;
  padding: 0.2em;
  color: black;
}
.background-img-tech .skill-list-container div {
  font-size: 12px;
}

/*
=============== 
Section4. Contact
===============
*/
.contact-container {
  margin: 1em;
}
.contact-container .contact-input-container .contact-email {
  font-family: "raleway";
  padding: 4px;
  border-radius: 2px;
  border: 1px solid #7c7b7c;
  width: -webkit-fill-available;
}
.contact-container .contact-input-container .contact-message {
  margin: 4px 0;
  font-family: "raleway";
  padding: 4px;
  border: 1px solid #7c7b7c;
  width: -webkit-fill-available;
}
.contact-container .contact-input-container .contact-submit-container {
  text-align: right;
}
.contact-container .contact-input-container .contact-submit-container .contact-submit {
  width: 70px;
  border-radius: 0px;
  border: 1px #888784 solid;
  padding: 4px 2px;
  color: #888784;
  background: #fff;
  font-size: 12px;
  font-weight: bold;
}
.contact-container .contact-input-container .contact-submit-container .contact-submit:hover {
  background-color: rgba(136, 135, 132, 0.1294117647);
}

/*
=============== 
Footer
===============
*/
footer {
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  bottom: 0;
  padding: 0.5em 0.2em 1em;
  margin-top: 5em;
}
footer .left-block {
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
footer .external-link .icon_blog:hover {
  color: #434343;
}
footer .external-link .icon_linkedin:hover {
  color: #017bb3;
}
footer .external-link .icon_twitter:hover {
  color: #1cccfc;
}
footer .external-link svg {
  color: #383838;
  font-size: 24px;
  margin: 4px;
  cursor: default;
}
footer .external-link svg:hover {
  transition: all 0.1s linear;
  transform: scale(1.2);
}
footer .copyright {
  margin: 0px;
  font-size: 12px;
  font-family: sans-serif;
}
footer .honourCode_PC {
  width: 500px;
  display: block;
  padding: 8px;
  font-family: sans-serif;
}
footer .honourCode_PC h4 {
  margin: 0px;
  font-size: 12px;
  color: #7d7d7d;
  font-weight: bold;
}
footer .honourCode_PC .text {
  font-size: 12px;
}
footer .honourCode_Phone {
  display: none;
}

/*
=============== 
Tablet Size Responsive Setting 
===============
*/
@media (max-width: 1024px) and (max-width: 768px) {
  .nav-container {
    grid-template-columns: auto;
    justify-content: center;
  }
  .section-title {
    margin-top: 1em;
  }
}
/*
=============== 
Phone Size Responsive Setting 
===============
*/
@media (max-width: 768px) {
  .header .nav-container {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
  }
  .header .nav-container .site-logo-s {
    height: 30px;
    margin: 8px;
  }
  .header .nav-container .nav-btn {
    display: inline-block;
    font-size: 35px;
    margin: 8px;
    border: none;
    background: none;
    color: grey;
  }
  .header .nav-container .nav {
    display: none;
  }
  .header .nav-container .banner-title h1 {
    font-size: 30px;
  }
  .header .nav-container .banner-title h1 span {
    font-size: 20px;
  }
  .top-banner-container {
    margin-top: 1em;
  }
  .section-title {
    margin: 1em 0 0.5em;
    line-height: 0.9em;
  }
  .react-swipeable-view-container div {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .mobile-worksample-container {
    display: block;
  }
  .mobile-worksample-container.css-19r6kue-MuiContainer-root {
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  .mobile-worksample-container .jFJUQD {
    padding: 0 0;
    font-size: 12px;
  }
  .mobile-worksample-container .tag-container {
    display: contents;
  }
  .mobile-worksample-container .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    color: #606277;
  }
  .mobile-worksample-container .icon_selector_selected {
    color: #707070;
    font-size: 9px;
    display: block;
    padding: 4px;
  }
  .mobile-worksample-container .icon_selector {
    color: #d9d9d9;
    font-size: 9px;
    display: block;
    padding: 4px;
  }
  .mobile-worksample-container .right-arrow-icon {
    vertical-align: middle;
    margin: auto 0;
  }
  .mobile-worksample-container .sample-contents-container {
    display: flex;
    flex-direction: column;
  }
  .mobile-worksample-container .mobile-comment {
    font-family: arial;
    color: #3f51b5;
    font-weight: normal;
    vertical-align: middle;
    cursor: default;
  }
  .mobile-worksample-container .mobile-tmp-box-container {
    align-items: center;
    overflow-y: hidden;
    transition: transform 0.5s ease-out;
    position: relative;
    height: 500px;
    display: block;
  }
  .mobile-worksample-container .mobile-tmp-box {
    animation: 0.8s slide-right;
    animation-delay: 0s;
  }
  @keyframes slide-right {
    from {
      left: 0px;
    }
    to {
      left: 600px;
    }
  }
  .pc-worksample {
    display: none !important;
  }
  .skill-list-container {
    grid-template-columns: auto !important;
  }
  .skill-list-container ul {
    list-style-type: none;
    border: none;
    border-style: none;
    background-color: #f9f9f9;
    margin: 5px 0.2em;
    padding: 1em;
  }
  .background-img-tech {
    background: rgba(255, 255, 255, 0.5882352941);
  }
  .background-img-tech::before {
    height: 500px;
    width: 100%;
    left: 0%;
    background-size: 170%;
    background-position: -100px 9px;
  }
  .css-xdmu94-MuiContainer-root {
    padding: 0 !important;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .profile-container div:first-child {
    padding: 0 4px;
    margin: 0;
    background-color: transparent;
  }
  .contact-container {
    grid-template-columns: auto;
  }
  footer {
    padding: 0.5em 0.2em 0;
    flex-direction: column;
  }
  footer .copyright {
    font-size: 8px;
  }
  footer .left-block {
    margin: 0 0 8px;
  }
  footer .external-link svg {
    font-size: 12px;
  }
  footer p {
    font-size: 7px;
    line-height: 1em;
    font-family: sans-serif;
  }
  footer .honourCode_PC {
    display: none !important;
  }
  footer .honourCode_Phone {
    display: block !important;
    padding: 0;
    margin: 0 0 4px;
  }
  footer .honourCode_Phone .text {
    font-size: 9px;
    line-height: 1em;
    font-family: sans-serif;
  }
}

