/*
=============== 
common
===============
*/
.sample-container-002 {
  font-size: 12px;
}
.sample-container-002 .sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}
.sample-container-002 .tag-container {
  text-align: left;
  display: inline;
  color: #000;
  font-weight: normal;
  cursor: default;
  width: max-content;
}
.sample-container-002 .tag-container .tech-tag {
  padding: 0px 12px;
  background: #D9D9D9;
  border-radius: 4px;
  font-size: 11px;
  margin: 0 4px;
  cursor: default;
  width: max-content;
  display: inline-block;
}
.sample-container-002 .tag-container .tech-tag:hover {
  background: rgba(128, 128, 128, 0.5490196078);
}

/*
  =============== 
   the worksample 
  ===============
  */
.sample-contents-container-002 .sample-input-container {
  font-weight: normal;
  color: #000;
  font-size: 12px;
}
.sample-contents-container-002 .table-container {
  font-weight: normal;
  color: #000;
  z-index: 1;
}
.sample-contents-container-002 .table-container .list-ttl {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 12px;
}
.sample-contents-container-002 .table-container .result-data-container {
  display: grid;
  position: relative;
  width: fit-content;
}
.sample-contents-container-002 .table-container .result-data-container span {
  margin-right: 4px;
}
.sample-contents-container-002 .table-container .result-data-container .contributors_list {
  margin: 0;
  padding: 0;
}
.sample-contents-container-002 .table-container .result-data-container .contributors_list li {
  list-style: none;
}
.sample-contents-container-002 .table-container .result-data-container .contributors_list .contributors_list_delete_icon {
  background: none;
  border: none;
}
.sample-contents-container-002 .login-tab {
  margin-top: 2em;
  display: flex;
  margin-left: 9px;
  font-size: 12px;
}
.sample-contents-container-002 .login-tab button {
  margin: 0 -1px;
}
.sample-contents-container-002 .login-tab button.login-tab-item-active {
  padding: 2px 4px;
}
.sample-contents-container-002 .login-tab-item-active {
  border-bottom: 2px solid rgb(96, 98, 119) !important;
  margin: 0px 0px;
  font-weight: bold;
}
.sample-contents-container-002 .login-controller-box {
  color: #000;
}
.sample-contents-container-002 .login-display-box {
  background: rgb(249, 249, 249);
  padding: 1em 3em;
  vertical-align: middle;
  margin: 0.5em 0 auto;
  height: max-content;
  grid-template-columns: 1fr 1fr;
  display: grid;
  border: 2px solid #888784;
  border: 2px solid #458fc0;
  border-radius: 7px;
}
.sample-contents-container-002 .login-display-box p:nth-child(2) {
  border-left: solid 1px black;
  padding-left: 20px;
}
.sample-contents-container-002 .login-display-box2 {
  background: rgb(249, 249, 249);
  padding: 1em 3em;
  vertical-align: middle;
  margin: 0.5em 0 auto;
  height: max-content;
  width: fit-content;
  border: 2px solid #10ff97;
  border-radius: 7px;
}
.sample-contents-container-002 .login-admin-container {
  font-size: 12px;
}
.sample-contents-container-002 .login-admin-container .ttl {
  margin: 0 2em 1em;
  border-bottom: solid 1px;
  text-align: left;
}
.sample-contents-container-002 .login-admin-container .ttl div {
  width: 150px;
  text-overflow: ellipsis;
}
.sample-contents-container-002 .login-admin-container .ttl div:nth-child(1) {
  width: 56px;
}
.sample-contents-container-002 .login-admin-container .ttl div:nth-child(7) {
  width: 200px;
}
.sample-contents-container-002 .login-admin-container .item {
  margin: 0 3em 0;
}
.sample-contents-container-002 .login-admin-container .item > div {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sample-contents-container-002 .login-admin-container .item > div:nth-child(1) {
  width: 44px;
}
.sample-contents-container-002 .login-admin-container .item > div:nth-child(7) {
  width: 200px;
}
.sample-contents-container-002 .login-admin-container .item > div svg {
  font-size: 20px;
}
.sample-contents-container-002 .login-admin-container .item .btn-sp {
  display: none;
}
.sample-contents-container-002 .login-admin-container .login-admin-container-user .ttl > div {
  width: 100px;
}
.sample-contents-container-002 .login-admin-container .login-admin-container-user .ttl > div:nth-child(1) {
  width: 35px;
}
.sample-contents-container-002 .login-admin-container .login-admin-container-user .ttl > div:nth-child(7) {
  width: 200px;
}
.sample-contents-container-002 .login-admin-container .login-admin-container-user .item > div {
  width: 184px;
}
.sample-contents-container-002 .login-admin-container .login-admin-container-user .item > div:nth-child(1) {
  width: 32px;
}
.sample-contents-container-002 .login-admin-container .login-admin-container-user .item > div:nth-child(7) {
  width: 350px;
}
.sample-contents-container-002 .login-admin-container .item-edit {
  margin: auto 3em;
  vertical-align: middle;
}
.sample-contents-container-002 .login-admin-container .item-edit div {
  width: 132px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto 0;
}
.sample-contents-container-002 .login-admin-container .item-edit div:nth-child(1) {
  width: 44px;
}
.sample-contents-container-002 .login-admin-container .item-edit div:nth-child(2) {
  width: 120px;
}
.sample-contents-container-002 .login-admin-container .item-edit div:nth-child(6) {
  width: 110px;
}
.sample-contents-container-002 .login-admin-container .item-edit div:nth-child(6) select {
  width: 86px;
}
.sample-contents-container-002 .login-admin-container .item-edit div:nth-child(7) {
  width: 200px;
}
.sample-contents-container-002 .login-admin-container .item-edit div svg {
  font-size: 20px;
}
.sample-contents-container-002 .login-admin-container .item-edit div select {
  border: 1px solid #000;
  height: 22px;
  margin-bottom: 4px;
}
.sample-contents-container-002 .login-admin-container .item-edit div .inputA {
  border-radius: 0;
  height: 22px;
  border: 1px solid #000;
  width: 100px;
  margin-bottom: 4px;
  padding-left: 2px;
}
.sample-contents-container-002 .login-admin-container .item-edit div .inputB {
  border-radius: 0;
  height: 22px;
  border: 1px solid #000;
  width: 100px;
  margin-bottom: 4px;
  padding-left: 2px;
}
.sample-contents-container-002 .login-input-container,
.sample-contents-container-002 .create-new-account-input-container {
  flex-direction: column;
  display: flex;
}
.sample-contents-container-002 .login-input-container-fit {
  width: fit-content;
}
.sample-contents-container-002 .login-input-container,
.sample-contents-container-002 .create-new-account-input-container,
.sample-contents-container-002 .login-container {
  font-size: 16px;
  align-items: center;
  align-content: center;
  margin: auto;
}
.sample-contents-container-002 .login-input-container h4,
.sample-contents-container-002 .create-new-account-input-container h4,
.sample-contents-container-002 .login-container h4 {
  margin: 0 3em 1em;
  font-weight: lighter;
  font-size: 16px;
}
.sample-contents-container-002 .login-input-container form,
.sample-contents-container-002 .create-new-account-input-container form,
.sample-contents-container-002 .login-container form {
  text-align: center;
}
.sample-contents-container-002 .login-input-container .login-input-container,
.sample-contents-container-002 .login-input-container .create-new-account-input-container,
.sample-contents-container-002 .create-new-account-input-container .login-input-container,
.sample-contents-container-002 .create-new-account-input-container .create-new-account-input-container,
.sample-contents-container-002 .login-container .login-input-container,
.sample-contents-container-002 .login-container .create-new-account-input-container {
  flex-direction: column;
  display: flex;
}
.sample-contents-container-002 .login-input-container label,
.sample-contents-container-002 .create-new-account-input-container label,
.sample-contents-container-002 .login-container label {
  text-align: left;
}
.sample-contents-container-002 .login-input-container svg,
.sample-contents-container-002 .create-new-account-input-container svg,
.sample-contents-container-002 .login-container svg {
  font-size: 14px;
  margin-right: 2px;
  display: inline;
  color: #606277;
}
.sample-contents-container-002 .login-input-container p,
.sample-contents-container-002 .create-new-account-input-container p,
.sample-contents-container-002 .login-container p {
  font-size: 9px;
  cursor: default;
  margin: 0 1em;
  display: inline-block;
}
.sample-contents-container-002 .login-input-container p:hover,
.sample-contents-container-002 .create-new-account-input-container p:hover,
.sample-contents-container-002 .login-container p:hover {
  text-decoration: underline;
}
.sample-contents-container-002 .login-input-container .login-input,
.sample-contents-container-002 .login-input-container .create-new-account-input,
.sample-contents-container-002 .create-new-account-input-container .login-input,
.sample-contents-container-002 .create-new-account-input-container .create-new-account-input,
.sample-contents-container-002 .login-container .login-input,
.sample-contents-container-002 .login-container .create-new-account-input {
  border-radius: 0;
  height: 38px;
  border: 1px solid #000;
  width: 230px;
  margin-bottom: 4px;
  padding-left: 12px;
}
.sample-contents-container-002 .login-input-container .account-err,
.sample-contents-container-002 .login-input-container .create-new-account-err,
.sample-contents-container-002 .create-new-account-input-container .account-err,
.sample-contents-container-002 .create-new-account-input-container .create-new-account-err,
.sample-contents-container-002 .login-container .account-err,
.sample-contents-container-002 .login-container .create-new-account-err {
  font-size: 16px;
  color: #e4001d;
  line-height: 1em;
  display: inherit;
  vertical-align: text-top;
  margin-bottom: 4px;
  width: 250px;
  text-align: left;
}
.sample-contents-container-002 .login-input-container .account-sub-msg,
.sample-contents-container-002 .create-new-account-input-container .account-sub-msg,
.sample-contents-container-002 .login-container .account-sub-msg {
  font-size: 10px;
  color: #000000;
  line-height: 1em;
  display: inherit;
  vertical-align: text-top;
  width: 250px;
  text-align: left;
  background: rgba(5, 150, 0, 0.231372549);
  padding: 4px;
  margin: 4px 0;
}
.sample-contents-container-002 .login-input-container .notification,
.sample-contents-container-002 .create-new-account-input-container .notification,
.sample-contents-container-002 .login-container .notification {
  font-size: 18px;
  margin: 8px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #fafafa;
  vertical-align: middle;
  padding: 6px 12px;
}
.sample-contents-container-002 .login-input-container .notification div,
.sample-contents-container-002 .create-new-account-input-container .notification div,
.sample-contents-container-002 .login-container .notification div {
  margin: auto 0;
}
.sample-contents-container-002 .login-input-container .account-err-msg,
.sample-contents-container-002 .create-new-account-input-container .account-err-msg,
.sample-contents-container-002 .login-container .account-err-msg {
  font-size: 18px;
  padding: 8px;
  margin: 8px 16px;
  border: 2px solid red;
  border-radius: 8px;
  background: rgba(222, 107, 107, 0.18);
  color: red;
}
.sample-contents-container-002 .login-input-container .account-err-msg div,
.sample-contents-container-002 .create-new-account-input-container .account-err-msg div,
.sample-contents-container-002 .login-container .account-err-msg div {
  margin: auto 0;
}
.sample-contents-container-002 .login-input-container .notification-container svg,
.sample-contents-container-002 .create-new-account-input-container .notification-container svg,
.sample-contents-container-002 .login-container .notification-container svg {
  font-size: 34px;
  margin-right: 2px;
}
.sample-contents-container-002 .login-input-container .login-contents-container,
.sample-contents-container-002 .create-new-account-input-container .login-contents-container,
.sample-contents-container-002 .login-container .login-contents-container {
  margin: 1em;
}
.sample-contents-container-002 .login-input-container .login-contents-container form,
.sample-contents-container-002 .create-new-account-input-container .login-contents-container form,
.sample-contents-container-002 .login-container .login-contents-container form {
  text-align: left;
}
.sample-contents-container-002 .login-input-container .login-contents-container .menu-item,
.sample-contents-container-002 .create-new-account-input-container .login-contents-container .menu-item,
.sample-contents-container-002 .login-container .login-contents-container .menu-item {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
}
.sample-contents-container-002 .login-input-container .login-container-txt,
.sample-contents-container-002 .create-new-account-input-container .login-container-txt,
.sample-contents-container-002 .login-container .login-container-txt {
  margin: 1em;
}
.sample-contents-container-002 .login-input-container .login-nav,
.sample-contents-container-002 .create-new-account-input-container .login-nav,
.sample-contents-container-002 .login-container .login-nav {
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  margin: 0 1em 1em;
  border-bottom: 1px solid #000;
}
.sample-contents-container-002 .login-input-container .login-nav span,
.sample-contents-container-002 .create-new-account-input-container .login-nav span,
.sample-contents-container-002 .login-container .login-nav span {
  background: #cdcdcd;
  border-radius: 2px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 4px 2px;
  padding: 2px 7px 0;
  vertical-align: middle;
  font-size: 12px;
}
.sample-contents-container-002 .login-input-container .login-nav div:nth-child(2),
.sample-contents-container-002 .create-new-account-input-container .login-nav div:nth-child(2),
.sample-contents-container-002 .login-container .login-nav div:nth-child(2) {
  font-size: 10px;
}
.sample-contents-container-002 .login-input-container .login-nav .logout-container,
.sample-contents-container-002 .create-new-account-input-container .login-nav .logout-container,
.sample-contents-container-002 .login-container .login-nav .logout-container {
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 5px;
  border: 1px solid #000;
}
.sample-contents-container-002 .login-input-container .link-btn,
.sample-contents-container-002 .create-new-account-input-container .link-btn,
.sample-contents-container-002 .login-container .link-btn {
  cursor: default;
  display: flex;
  font-size: 16px;
  border: none;
  background: none;
  text-decoration: underline;
  font-weight: lighter;
  line-height: 1.4em;
}
.sample-contents-container-002 .login-input-container .logout-btn,
.sample-contents-container-002 .create-new-account-input-container .logout-btn,
.sample-contents-container-002 .login-container .logout-btn {
  width: fit-content;
  background: none;
  padding: 4px 8px;
  font-size: 12px;
  border: 0px;
}
.sample-contents-container-002 .login-input-container .logout-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .logout-btn:disabled,
.sample-contents-container-002 .login-container .logout-btn:disabled {
  border: 1px solid #c4c4c4;
}
.sample-contents-container-002 .login-input-container .logout-btn:disabled:hover, .sample-contents-container-002 .login-input-container .logout-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .logout-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .logout-btn:disabled:active,
.sample-contents-container-002 .login-container .logout-btn:disabled:hover,
.sample-contents-container-002 .login-container .logout-btn:disabled:active {
  background: none;
}
.sample-contents-container-002 .login-input-container .logout-btn:hover, .sample-contents-container-002 .login-input-container .logout-btn:active,
.sample-contents-container-002 .create-new-account-input-container .logout-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .logout-btn:active,
.sample-contents-container-002 .login-container .logout-btn:hover,
.sample-contents-container-002 .login-container .logout-btn:active {
  background: #D9D9D9;
}
.sample-contents-container-002 .login-input-container .logout-btn svg,
.sample-contents-container-002 .create-new-account-input-container .logout-btn svg,
.sample-contents-container-002 .login-container .logout-btn svg {
  vertical-align: text-bottom;
  font-size: 16px;
  margin: auto 2px;
}
.sample-contents-container-002 .login-input-container .login-btn,
.sample-contents-container-002 .login-input-container .register-btn,
.sample-contents-container-002 .create-new-account-input-container .login-btn,
.sample-contents-container-002 .create-new-account-input-container .register-btn,
.sample-contents-container-002 .login-container .login-btn,
.sample-contents-container-002 .login-container .register-btn {
  width: fit-content;
  padding: 4px 8px;
  font-size: 16px;
  border-radius: 4px;
  background: #000;
  color: white;
}
.sample-contents-container-002 .login-input-container .login-btn:disabled,
.sample-contents-container-002 .login-input-container .register-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .login-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .register-btn:disabled,
.sample-contents-container-002 .login-container .login-btn:disabled,
.sample-contents-container-002 .login-container .register-btn:disabled {
  border: 1px solid #c4c4c4;
  background: #D9D9D9;
}
.sample-contents-container-002 .login-input-container .login-btn:disabled:hover, .sample-contents-container-002 .login-input-container .login-btn:disabled:active,
.sample-contents-container-002 .login-input-container .register-btn:disabled:hover,
.sample-contents-container-002 .login-input-container .register-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .login-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .login-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .register-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .register-btn:disabled:active,
.sample-contents-container-002 .login-container .login-btn:disabled:hover,
.sample-contents-container-002 .login-container .login-btn:disabled:active,
.sample-contents-container-002 .login-container .register-btn:disabled:hover,
.sample-contents-container-002 .login-container .register-btn:disabled:active {
  border: 1px solid #c4c4c4;
  background: #D9D9D9;
}
.sample-contents-container-002 .login-input-container .login-btn:hover, .sample-contents-container-002 .login-input-container .login-btn:active,
.sample-contents-container-002 .login-input-container .register-btn:hover,
.sample-contents-container-002 .login-input-container .register-btn:active,
.sample-contents-container-002 .create-new-account-input-container .login-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .login-btn:active,
.sample-contents-container-002 .create-new-account-input-container .register-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .register-btn:active,
.sample-contents-container-002 .login-container .login-btn:hover,
.sample-contents-container-002 .login-container .login-btn:active,
.sample-contents-container-002 .login-container .register-btn:hover,
.sample-contents-container-002 .login-container .register-btn:active {
  background: rgba(0, 0, 0, 0.8);
}
.sample-contents-container-002 .login-input-container .login-btn .login-btn-link,
.sample-contents-container-002 .login-input-container .register-btn .login-btn-link,
.sample-contents-container-002 .create-new-account-input-container .login-btn .login-btn-link,
.sample-contents-container-002 .create-new-account-input-container .register-btn .login-btn-link,
.sample-contents-container-002 .login-container .login-btn .login-btn-link,
.sample-contents-container-002 .login-container .register-btn .login-btn-link {
  text-decoration: none;
  color: white;
}
.sample-contents-container-002 .login-input-container .edit-btn,
.sample-contents-container-002 .login-input-container .update2-btn,
.sample-contents-container-002 .login-input-container .cancel-btn,
.sample-contents-container-002 .login-input-container .delete2-btn,
.sample-contents-container-002 .create-new-account-input-container .edit-btn,
.sample-contents-container-002 .create-new-account-input-container .update2-btn,
.sample-contents-container-002 .create-new-account-input-container .cancel-btn,
.sample-contents-container-002 .create-new-account-input-container .delete2-btn,
.sample-contents-container-002 .login-container .edit-btn,
.sample-contents-container-002 .login-container .update2-btn,
.sample-contents-container-002 .login-container .cancel-btn,
.sample-contents-container-002 .login-container .delete2-btn {
  width: fit-content;
  padding: 0px 16px;
  height: 18px;
  font-size: 12px;
  margin: 2px;
  color: #000;
  border: 1px solid #555;
}
.sample-contents-container-002 .login-input-container .edit-btn:disabled,
.sample-contents-container-002 .login-input-container .update2-btn:disabled,
.sample-contents-container-002 .login-input-container .cancel-btn:disabled,
.sample-contents-container-002 .login-input-container .delete2-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .edit-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .update2-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .cancel-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .delete2-btn:disabled,
.sample-contents-container-002 .login-container .edit-btn:disabled,
.sample-contents-container-002 .login-container .update2-btn:disabled,
.sample-contents-container-002 .login-container .cancel-btn:disabled,
.sample-contents-container-002 .login-container .delete2-btn:disabled {
  border: 1px solid #c4c4c4;
  background: #D9D9D9;
  color: grey;
}
.sample-contents-container-002 .login-input-container .edit-btn:disabled:hover, .sample-contents-container-002 .login-input-container .edit-btn:disabled:active,
.sample-contents-container-002 .login-input-container .update2-btn:disabled:hover,
.sample-contents-container-002 .login-input-container .update2-btn:disabled:active,
.sample-contents-container-002 .login-input-container .cancel-btn:disabled:hover,
.sample-contents-container-002 .login-input-container .cancel-btn:disabled:active,
.sample-contents-container-002 .login-input-container .delete2-btn:disabled:hover,
.sample-contents-container-002 .login-input-container .delete2-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .edit-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .edit-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .update2-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .update2-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .cancel-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .cancel-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .delete2-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .delete2-btn:disabled:active,
.sample-contents-container-002 .login-container .edit-btn:disabled:hover,
.sample-contents-container-002 .login-container .edit-btn:disabled:active,
.sample-contents-container-002 .login-container .update2-btn:disabled:hover,
.sample-contents-container-002 .login-container .update2-btn:disabled:active,
.sample-contents-container-002 .login-container .cancel-btn:disabled:hover,
.sample-contents-container-002 .login-container .cancel-btn:disabled:active,
.sample-contents-container-002 .login-container .delete2-btn:disabled:hover,
.sample-contents-container-002 .login-container .delete2-btn:disabled:active {
  border: 1px solid #c4c4c4;
  background: #D9D9D9;
}
.sample-contents-container-002 .login-input-container .edit-btn:hover, .sample-contents-container-002 .login-input-container .edit-btn:active,
.sample-contents-container-002 .login-input-container .update2-btn:hover,
.sample-contents-container-002 .login-input-container .update2-btn:active,
.sample-contents-container-002 .login-input-container .cancel-btn:hover,
.sample-contents-container-002 .login-input-container .cancel-btn:active,
.sample-contents-container-002 .login-input-container .delete2-btn:hover,
.sample-contents-container-002 .login-input-container .delete2-btn:active,
.sample-contents-container-002 .create-new-account-input-container .edit-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .edit-btn:active,
.sample-contents-container-002 .create-new-account-input-container .update2-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .update2-btn:active,
.sample-contents-container-002 .create-new-account-input-container .cancel-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .cancel-btn:active,
.sample-contents-container-002 .create-new-account-input-container .delete2-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .delete2-btn:active,
.sample-contents-container-002 .login-container .edit-btn:hover,
.sample-contents-container-002 .login-container .edit-btn:active,
.sample-contents-container-002 .login-container .update2-btn:hover,
.sample-contents-container-002 .login-container .update2-btn:active,
.sample-contents-container-002 .login-container .cancel-btn:hover,
.sample-contents-container-002 .login-container .cancel-btn:active,
.sample-contents-container-002 .login-container .delete2-btn:hover,
.sample-contents-container-002 .login-container .delete2-btn:active {
  background: #D9D9D9;
}
.sample-contents-container-002 .login-input-container .signin-btn,
.sample-contents-container-002 .login-input-container .delete-btn,
.sample-contents-container-002 .login-input-container .update-btn,
.sample-contents-container-002 .create-new-account-input-container .signin-btn,
.sample-contents-container-002 .create-new-account-input-container .delete-btn,
.sample-contents-container-002 .create-new-account-input-container .update-btn,
.sample-contents-container-002 .login-container .signin-btn,
.sample-contents-container-002 .login-container .delete-btn,
.sample-contents-container-002 .login-container .update-btn {
  width: fit-content;
  background: none;
  padding: 4px 8px;
  font-size: 10px;
}
.sample-contents-container-002 .login-input-container .signin-btn:disabled,
.sample-contents-container-002 .login-input-container .delete-btn:disabled,
.sample-contents-container-002 .login-input-container .update-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .signin-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .update-btn:disabled,
.sample-contents-container-002 .login-container .signin-btn:disabled,
.sample-contents-container-002 .login-container .delete-btn:disabled,
.sample-contents-container-002 .login-container .update-btn:disabled {
  border: 1px solid #c4c4c4;
}
.sample-contents-container-002 .login-input-container .signin-btn:disabled:hover, .sample-contents-container-002 .login-input-container .signin-btn:disabled:active,
.sample-contents-container-002 .login-input-container .delete-btn:disabled:hover,
.sample-contents-container-002 .login-input-container .delete-btn:disabled:active,
.sample-contents-container-002 .login-input-container .update-btn:disabled:hover,
.sample-contents-container-002 .login-input-container .update-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .signin-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .signin-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .update-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .update-btn:disabled:active,
.sample-contents-container-002 .login-container .signin-btn:disabled:hover,
.sample-contents-container-002 .login-container .signin-btn:disabled:active,
.sample-contents-container-002 .login-container .delete-btn:disabled:hover,
.sample-contents-container-002 .login-container .delete-btn:disabled:active,
.sample-contents-container-002 .login-container .update-btn:disabled:hover,
.sample-contents-container-002 .login-container .update-btn:disabled:active {
  background: none;
}
.sample-contents-container-002 .login-input-container .signin-btn:hover, .sample-contents-container-002 .login-input-container .signin-btn:active,
.sample-contents-container-002 .login-input-container .delete-btn:hover,
.sample-contents-container-002 .login-input-container .delete-btn:active,
.sample-contents-container-002 .login-input-container .update-btn:hover,
.sample-contents-container-002 .login-input-container .update-btn:active,
.sample-contents-container-002 .create-new-account-input-container .signin-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .signin-btn:active,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:active,
.sample-contents-container-002 .create-new-account-input-container .update-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .update-btn:active,
.sample-contents-container-002 .login-container .signin-btn:hover,
.sample-contents-container-002 .login-container .signin-btn:active,
.sample-contents-container-002 .login-container .delete-btn:hover,
.sample-contents-container-002 .login-container .delete-btn:active,
.sample-contents-container-002 .login-container .update-btn:hover,
.sample-contents-container-002 .login-container .update-btn:active {
  background: #D9D9D9;
}
.sample-contents-container-002 .login-input-container .signin-btn .login-btn-link,
.sample-contents-container-002 .login-input-container .delete-btn .login-btn-link,
.sample-contents-container-002 .login-input-container .update-btn .login-btn-link,
.sample-contents-container-002 .create-new-account-input-container .signin-btn .login-btn-link,
.sample-contents-container-002 .create-new-account-input-container .delete-btn .login-btn-link,
.sample-contents-container-002 .create-new-account-input-container .update-btn .login-btn-link,
.sample-contents-container-002 .login-container .signin-btn .login-btn-link,
.sample-contents-container-002 .login-container .delete-btn .login-btn-link,
.sample-contents-container-002 .login-container .update-btn .login-btn-link {
  text-decoration: none;
  color: black;
}
.sample-contents-container-002 .login-input-container .delete-btn,
.sample-contents-container-002 .create-new-account-input-container .delete-btn,
.sample-contents-container-002 .login-container .delete-btn {
  border: 2px solid #dd2121;
  background: #dd0046;
  font-size: 16px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}
.sample-contents-container-002 .login-input-container .delete-btn:hover, .sample-contents-container-002 .login-input-container .delete-btn:active,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:hover,
.sample-contents-container-002 .create-new-account-input-container .delete-btn:active,
.sample-contents-container-002 .login-container .delete-btn:hover,
.sample-contents-container-002 .login-container .delete-btn:active {
  background: rgba(182, 28, 28, 0.89);
}
.sample-contents-container-002 .login-input-container .update-btn,
.sample-contents-container-002 .create-new-account-input-container .update-btn,
.sample-contents-container-002 .login-container .update-btn {
  border: 2px solid #009c26;
  background: #00dd34;
  font-size: 16px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}
.sample-contents-container-002 .login-input-container .update-btn:disabled,
.sample-contents-container-002 .create-new-account-input-container .update-btn:disabled,
.sample-contents-container-002 .login-container .update-btn:disabled {
  background: #c4c4c4;
}
.sample-contents-container-002 .login-input-container .update-btn:disabled:hover, .sample-contents-container-002 .login-input-container .update-btn:disabled:active,
.sample-contents-container-002 .create-new-account-input-container .update-btn:disabled:hover,
.sample-contents-container-002 .create-new-account-input-container .update-btn:disabled:active,
.sample-contents-container-002 .login-container .update-btn:disabled:hover,
.sample-contents-container-002 .login-container .update-btn:disabled:active {
  background: rgba(31, 135, 103, 0.89);
}
.sample-contents-container-002 .login-input-container .google-login-btn,
.sample-contents-container-002 .create-new-account-input-container .google-login-btn,
.sample-contents-container-002 .login-container .google-login-btn {
  margin: 5px 0;
  border: 1px solid #c4c4c4;
  font-size: 12px;
  vertical-align: -webkit-baseline-middle;
  align-items: flex-end;
  height: 29px;
  vertical-align: top;
}
.sample-contents-container-002 .login-input-container .google-login-btn svg,
.sample-contents-container-002 .create-new-account-input-container .google-login-btn svg,
.sample-contents-container-002 .login-container .google-login-btn svg {
  font-size: 16px;
  margin: auto 2px;
}
.sample-contents-container-002 .login-input-container .login-input-ttl,
.sample-contents-container-002 .create-new-account-input-container .login-input-ttl,
.sample-contents-container-002 .login-container .login-input-ttl {
  text-align: left;
  margin-bottom: 16px;
}
.sample-contents-container-002 .login-input-container .login-input-ttl:hover, .sample-contents-container-002 .login-input-container .login-input-ttl:active,
.sample-contents-container-002 .create-new-account-input-container .login-input-ttl:hover,
.sample-contents-container-002 .create-new-account-input-container .login-input-ttl:active,
.sample-contents-container-002 .login-container .login-input-ttl:hover,
.sample-contents-container-002 .login-container .login-input-ttl:active {
  text-decoration: none;
}
.sample-contents-container-002 .login-input-container .login-input-txt,
.sample-contents-container-002 .create-new-account-input-container .login-input-txt,
.sample-contents-container-002 .login-container .login-input-txt {
  text-align: left;
  margin-bottom: 8px;
}

@keyframes slide {
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide {
  100% {
    -webkit-transform: translateY(0%);
  }
}
/*
  =============== 
  Phone Size Responsive Setting 
  ===============
  */
@media (max-width: 768px) {
  .sample-container-002 {
    padding: 0;
  }
  .sample-container-002 .tag-container {
    display: initial;
  }
  .sample-contents-container-002 {
    display: block;
  }
  .sample-contents-container-002 .login-controller-box {
    width: auto;
  }
  .sample-contents-container-002 .login-admin-container .ttl {
    margin: 0 4px 1em;
  }
  .sample-contents-container-002 .login-admin-container .item {
    margin: 0 4px 1em;
  }
  .sample-contents-container-002 .login-admin-container .item .btn-pc {
    display: none;
  }
  .sample-contents-container-002 .login-admin-container .item .btn-sp {
    display: flex;
  }
  .sample-contents-container-002 .login-admin-container .item .edit2-btn-bg,
  .sample-contents-container-002 .login-admin-container .item .delete3-btn-bg {
    position: relative;
    background: rgb(211, 20, 103);
    background: linear-gradient(to right top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
    cursor: default;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
    animation-iteration-count: infinite;
    animation-duration: 275ms;
    height: 16px;
    width: 16px;
  }
  .sample-contents-container-002 .login-admin-container .item .edit2-btn,
  .sample-contents-container-002 .login-admin-container .item .delete3-btn {
    height: auto;
    width: auto;
  }
  .sample-contents-container-002 .login-admin-container .item .edit2-btn:hover .edit2-btn-bg,
  .sample-contents-container-002 .login-admin-container .item .edit2-btn:hover .delete3-btn-bg,
  .sample-contents-container-002 .login-admin-container .item .delete3-btn:hover .edit2-btn-bg,
  .sample-contents-container-002 .login-admin-container .item .delete3-btn:hover .delete3-btn-bg {
    background-position: 0 100%;
  }
  .sample-contents-container-002 .login-admin-container .item-edit {
    display: block;
  }
  .sample-contents-container-002 .login-container h4, .sample-contents-container-002 .login-container .login-nav {
    margin: 0 4px 1em;
  }
}
/*
  =============== 
  Tablet Size Responsive Setting 
  ===============
  */

