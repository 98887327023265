/*
=============== 
common
===============
*/
.sample-container {
  padding: 0 20px;
  font-size: 12px;
}
.sample-container .sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}
.sample-container .sample-title-container .tag-container {
  text-align: right;
  display: inline-flex;
  color: #000;
  font-weight: normal;
}
.sample-container .sample-title-container .tag-container .tech-tag {
  padding: 0px 12px;
  background: #D9D9D9;
  border-radius: 4px;
  font-size: 11px;
  margin: 0 4px;
  cursor: default;
}
.sample-container .sample-title-container .tag-container .tech-tag:hover {
  background: #8080808c;
}

/*
=============== 
 the worksample 
===============
*/
.sample-contents-container-007 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.sample-contents-container-007 .sample-input-container {
  font-family: sans-serif;
  font-weight: normal;
  color: #000;
  font-size: 12px;
}
.sample-contents-container-007 .table-container {
  font-weight: normal;
  color: #000;
  z-index: 1;
}
.sample-contents-container-007 .table-container .list-ttl {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 12px;
}

/*
=============== 
Phone Size Responsive Setting 
===============
*/
@media (max-width: 768px) {
  .sample-container {
    padding: 0;
  }

  .sample-contents-container-007 {
    display: block;
  }
}
/*
=============== 
Tablet Size Responsive Setting 
===============
*/

