/*
=============== 
common
===============
*/
.sample-title {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0 8px;
  color: #000;
}

.sample-data {
  font-family: Arial, Helvetica, sans-serif;
}

.tag-container {
  text-align: left;
  display: inline-flex;
  color: #000;
  font-weight: normal;
  width: max-content;
}
.tag-container .tech-tag {
  padding: 0px 12px;
  background: #D9D9D9;
  border-radius: 4px;
  font-size: 11px;
  margin: 0 4px;
  height: 16px;
  cursor: default;
}
.tag-container .tech-tag:hover {
  background: rgba(128, 128, 128, 0.5490196078);
}

.sample-title-container-006 {
  font-weight: normal;
  color: black;
}
.sample-title-container-006 .table-title-container {
  border-radius: 0;
  font-family: sans-serif;
  font-weight: 200;
  height: 30px;
  color: #000;
  font-size: 12px;
}

/*
  =============== 
  the worksample 
  ===============
  */
.sample-contents-container-006 {
  display: grid;
  grid-template-columns: 200px 4fr;
}
.sample-contents-container-006 .sample-title-container-006 .select-field {
  width: auto;
}
.sample-contents-container-006 .sample-title-container-006 .update-btn {
  border: 1px solid #666;
  padding: 2px 4px;
  margin: 8px 0;
  border-radius: 4px;
  background: #000;
  color: white;
}
.sample-contents-container-006 .sample-title-container-006 .update-btn:hover, .sample-contents-container-006 .sample-title-container-006 .update-btn:active {
  background: rgba(0, 0, 0, 0.7882352941);
}
.sample-contents-container-006 .sample-title-container-006 .update-btn:disabled {
  cursor: default;
  background: #d9d9d9;
  border: none;
}
.sample-contents-container-006 .sample-table-container-006 {
  justify-content: center;
  margin: 20px 0 20px 20px;
}
.sample-contents-container-006 .sample-table-container-006 h4 {
  color: #000;
  font-weight: normal;
}
.sample-contents-container-006 .sample-table-container-006 .rate-tab {
  display: flex;
  justify-content: space-evenly;
  margin: 0 35%;
}
.sample-contents-container-006 .sample-table-container-006 .rate-tab button {
  padding: 0px 8px;
}
.sample-contents-container-006 .sample-table-container-006 .rate-tab .term-ttl-active {
  background: rgba(178, 178, 176, 0.231372549);
  padding: 0px 8px;
  border-radius: 8px;
}
.sample-contents-container-006 .sample-table-container-006 .graph-container .recharts-layer {
  font-size: 8px;
}
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-pc {
  display: block;
}
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-sp {
  display: none;
}
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-pc .hover-label,
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-sp .hover-label {
  background-color: #fff;
  border: 1px black solid;
  font-weight: normal;
  font-family: sans-serif;
  font-size: 9px;
  padding: 0px 2px;
  height: fit-content;
  border-radius: 0;
}
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-pc .hover-label:hover,
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-sp .hover-label:hover {
  border-radius: 0;
}
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-pc .hover-label p,
.sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-sp .hover-label p {
  padding: 0;
}

@keyframes bar-animation {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
  =============== 
  Phone Size Responsive Setting 
  ===============
  */
@media (max-width: 768px) {
  .sample-contents-container-006 {
    display: block;
  }
  .sample-contents-container-006 .tag-container {
    display: contents;
  }
  .sample-contents-container-006 .sample-title-container-006 {
    padding: 0 0;
  }
  .sample-contents-container-006 .sample-title-container-006 .sample-title {
    padding: 0 0;
  }
  .sample-contents-container-006 .sample-table-container-006 {
    margin: 0;
  }
  .sample-contents-container-006 .sample-table-container-006 h4 {
    padding: 0 0;
  }
  .sample-contents-container-006 .sample-table-container-006 .graph-container {
    margin: 0;
    height: 150px;
    font-size: 9px;
  }
  .sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-pc {
    display: none;
  }
  .sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-sp {
    display: block;
  }
  .sample-contents-container-006 .sample-table-container-006 .graph-container .line-graph-sp svg.recharts-surface {
    position: absolute;
    left: -40px;
    overflow: hidden;
  }
}
/*
  =============== 
  Tablet Size Responsive Setting 
  ===============
  */

